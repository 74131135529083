import React, { useState, useEffect } from 'react';
import { useLockBodyScroll } from 'react-use';
import { slide as Menu } from 'react-burger-menu';
import { BurgerMenuContainer, BurgerMenuLinks } from '../style/menu-style';
import { useNavigate } from 'react-router-dom';
import bar from '../../assets/logos/bar.svg';
import cross from '../../assets/logos/cross.svg';
import { HaningeNaprapatLogo } from '../style/menu-style';

export const HamburgerMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuWidth, setMenuWidth] = useState('600px');
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useLockBodyScroll(menuOpen);

  const handleStateChange = (state: { isOpen: boolean }) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = (path: string) => {
    setMenuOpen(false);
    navigate(path);
  };



  // Function to determine if the current screen size is mobile/tablet
  const updateScreenSize = () => {
    const screenWidth = window.innerWidth;
    setIsMobile(screenWidth < 768); // Mobile devices
    setIsTablet(screenWidth >= 768 && screenWidth < 1024); // Tablet devices

    if (screenWidth < 768) {
      setMenuWidth('100%');
    } else if (screenWidth >= 768 && screenWidth < 1024) {
      setMenuWidth('650px');
    } else {
      setMenuWidth('500px');
    }
  };

  // Update on window resize and on component mount
  useEffect(() => {
    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  // Update `isScrolled` state based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Styling for burger button position dynamically based on device and scroll
  const customStyles = {
    bmBurgerButton: {
      position: 'absolute',
      width: '26px',
      height: '20px',
      right: isTablet ? '40px' : '26px', // Adjust right position for tablet
      top: isScrolled
        ? isTablet
          ? '35px' // When scrolled on tablet
          : '24px'  // When scrolled on mobile
        : isTablet
          ? '55px' // When not scrolled on tablet
          : '40px'  // When not scrolled on mobile
    },
    bmCrossButton: {
      margin: '18px 15px',
    },
    bmMenuWrap: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      height: '100%',
      width: menuWidth,
      zIndex: '1000',
      backgroundColor: 'var(--main-light)',
    },
    bmMenu: {
      background: 'var(--main-light)',
      padding: '8em 2.5em 0',
      width: menuWidth,
    },
    bmItemList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textTransform: 'uppercase',
    },
    bmItem: {
      margin: '20px 0',
    },
  };

  // Render HamburgerMenu on mobile or tablet
  if (!isMobile && !isTablet) {
    return null;
  }

  return (
    <BurgerMenuContainer $isScrolled={isScrolled}>
      <HaningeNaprapatLogo $isScrolled={isScrolled} />
      <Menu
        customBurgerIcon={<img src={bar} alt="open" />}
        customCrossIcon={<img src={cross} alt="close" />}
        right
        disableAutoFocus
        isOpen={menuOpen}
        onStateChange={handleStateChange}
        styles={customStyles}
      >
        <BurgerMenuLinks onClick={() => closeMenu('/')} id="home" to="/">
          Hem
        </BurgerMenuLinks>
        <BurgerMenuLinks onClick={() => closeMenu('/naprapat')} id="naprapat" to="/naprapat">
          Naprapat
        </BurgerMenuLinks>
        <BurgerMenuLinks onClick={() => closeMenu('/massage')} id="massage" to="/massage">
          Massage
        </BurgerMenuLinks>
        <BurgerMenuLinks onClick={() => closeMenu('/om-oss')} id="omoss" to="/om-oss">
          Om oss
        </BurgerMenuLinks>
        <BurgerMenuLinks onClick={() => closeMenu('#kontakt')} id="kontakt" to="/#kontakta-oss">
          Kontakt
        </BurgerMenuLinks>
      </Menu>
    </BurgerMenuContainer>
  );
};
