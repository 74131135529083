import React, { StrictMode } from 'react';
import './App.css';
import { AppRoutes } from './components/utils/routes';
import { GlobalStyles } from "./components/style/GlobalStyles";


function App() {
  return (

    <StrictMode>
      <GlobalStyles />
      <div className="App">
        <AppRoutes />
      </div>
    </StrictMode>
  );
}

export default App;
