import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Lib from '../style/lib';

export const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  // Dynamically add a noindex meta tag when this component is mounted
  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex';
    document.head.appendChild(metaTag);

    // Clean up: remove the noindex meta tag when the component is unmounted
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  return (
    <Lib.PlainContainer>
      <Lib.Header5>404 - Page Not Found</Lib.Header5>
      <Lib.BreadParagraph>
        Oh nej! Sidan du letar efter finns inte.
      </Lib.BreadParagraph>
      <Lib.CTAButton onClick={handleGoBack}>
        Go Back
      </Lib.CTAButton>
    </Lib.PlainContainer>
  );
};