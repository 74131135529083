import React, { useEffect, useState } from 'react';
import * as DM from '../style/menu-style';
import { HaningeNaprapatLogo } from '../style/menu-style';



export const DesktopMenu: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <DM.DesktopMenuContainer $isScrolled={isScrolled}>
      <HaningeNaprapatLogo $isScrolled={isScrolled} />
      <DM.DesktopLinkContainer>

        <DM.BehandlingarContainer
          onMouseEnter={() => setIsDropdownVisible(true)}
          onMouseLeave={() => setIsDropdownVisible(false)}
        >
          <DM.DeskMenuLinks id="behandlingar">
            Behandlingar
          </DM.DeskMenuLinks>

          {isDropdownVisible && (
            <DM.DropdownMenu>
              <DM.DropdownLink to="/naprapat">Naprapat</DM.DropdownLink>
              <DM.DropdownLink to="/massage">Massage</DM.DropdownLink>
            </DM.DropdownMenu>
          )}
        </DM.BehandlingarContainer>


        <DM.DeskMenuLinks id="omoss" to="/om-oss">
          Om oss
        </DM.DeskMenuLinks>
        <DM.DeskMenuLinks id="kontakt" to="/#kontakta-oss">
          Kontakt
        </DM.DeskMenuLinks>
      </DM.DesktopLinkContainer>
    </DM.DesktopMenuContainer>
  );
};
