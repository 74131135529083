import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
    background-color: var(--main-dark);
    padding: 3rem 1rem;

    @media (min-width: 768px) {
      padding: 4rem 3rem 2rem;
  }

  @media (min-width: 1024px) {
    padding: 4rem 3rem 3rem;
  }
  `


export const FootRowColumn = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 2rem;
padding: 0 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 3rem;
  }
`

export const FootRow = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: flex-start
  `;

export const FootColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 28px 0;

    @media (min-width: 768px) {
      margin: 1rem 0;
    }

    @media (min-width: 1024px) {
      margin: 1rem 0;
    }
  `

export const FootCTA = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
margin: 0;

@media (min-width: 768px) {

}

@media (min-width: 1024px) {
  flex-direction: row;
  gap: 8rem;
}
`

export const FotLocHeader = styled.h5`
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--main-light);
    margin: 1rem 0 0;
    text-align: left;

    @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
  `

export const FotLocAdress = styled.p`
font-size: 14px;
font-weight: 300;
text-align: left;
color: var(--main-light);
margin-bottom: 12px;
margin-top: 5px;

@media (min-width: 768px) {
    font-size: 16px;
    margin-top: 2px;
    margin-bottom: 15px;
  }

@media (min-width: 1024px) {
    font-size: 18px;
    margin-bottom: 18px;
  }
`

export const TreatmentIcon2 = styled.img`
  width: 30px; 
  height: auto; 
  margin-right: 12px;
  `;

export const FootLinks = styled(Link) <{ to: string }>`
  position: relative;
  color: var(--main-light);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  margin: 5px 0;

  &:hover {
    color: var(--accent-green);
  }

   /* Tablet */
   @media (min-width: 768px) {
    font-size: 16px; 
  }
`;


export const DetailsRow = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 3px;
  `;

export const FootLine = styled.div`
width: 100%;
height: 1px;
background-color: var(--main-light);
margin: 2rem 0 8px 0;

@media (min-width: 768px) {
    margin: 3rem 0 8px 0;
  }

  @media (min-width: 1024px) {
    margin: 5rem 0 8px 0;
  }
`;


export const FootDetails = styled.p`
font-size: 8px;
font-weight: 300;
color: var(--main-light);
margin-bottom: 12px;
margin-top: 2px;

@media (min-width: 768px) {
    font-size: 10px;
  }
`;

export const FootA = styled.a`
font-size: 8px;
font-weight: 300;
color: var(--main-light);
margin-bottom: 12px;
margin-top: 5px;
text-decoration: none;

@media (min-width: 768px) {
    font-size: 10px;
  }

  &:hover {
    color: var(--accent-yellow);
  }
`;

export const ImgA = styled.a`
  display: inline-block;
  text-decoration: none;
`;

export const AndTechA = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 5px;
  margin-bottom: -3px;
`;