import React, { useState, useEffect } from "react";
import * as ReviewLib from "../style/review-style";
import { ReviewsData } from "../utils/reviews-data";

// Responsive logic 
const getVisibleReviewsCount = () => {
  const width = window.innerWidth;
  if (width < 768) return 1;
  if (width < 1024) return 2;
  return 3;
};

export const Review: React.FC = () => {
  const [startIndex, setStartIndex] = useState(0);
  const [visibleReviews, setVisibleReviews] = useState(ReviewsData.slice(0, getVisibleReviewsCount()));
  const [fadeOut, setFadeOut] = useState(false);

  // Function to get the correct slice of reviews, with wrapping if necessary
  const getSlicedReviews = (startIdx: number) => {
    const visibleCount = getVisibleReviewsCount();
    return [
      ...ReviewsData.slice(startIdx, startIdx + visibleCount),
      ...ReviewsData.slice(0, Math.max(0, (startIdx + visibleCount) - ReviewsData.length))
    ];
  };

  // Rotate reviews with fade-out and wrapping logic
  useEffect(() => {
    const intervalId = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        const newStartIndex = (startIndex + getVisibleReviewsCount()) % ReviewsData.length;
        setStartIndex(newStartIndex);
        setVisibleReviews(getSlicedReviews(newStartIndex));
        setFadeOut(false);
      }, 900);
    }, 9000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [startIndex]);

  // Handle resizing the window and updating the number of visible reviews
  useEffect(() => {
    const handleResize = () => {
      setVisibleReviews(getSlicedReviews(startIndex));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [startIndex]);

  return (
    <ReviewLib.ReviewContainer>
      {visibleReviews.map((review) => (
        <ReviewLib.ReviewContainerColumn
          key={review.id}
          $fadeOut={fadeOut}>

          <ReviewLib.ReviewParagraph>
            {review.text}
          </ReviewLib.ReviewParagraph>

          <ReviewLib.NameParagraph>{
            review.authorName}
          </ReviewLib.NameParagraph>

        </ReviewLib.ReviewContainerColumn>
      ))}
    </ReviewLib.ReviewContainer>
  );
};
