import React from "react";
import * as Lib from "../style/lib";
import * as OM from "../style/about-style";
import * as NM from "../style/nap-mas-style";
import { TheTeam } from "../utils/team";
import massageImg from "../../assets/img/ryggmassage-2000px.jpg";
import massage from '../../assets/logos/massage-icon.svg';
import checkicon from "../../assets/logos/check.png";

export const Massage: React.FC = () => {
  return (
    <>
      <NM.NaprapatImgWithOverlay src={massageImg}>
        <NM.AlignContainer>
          <NM.IconHeaderContainer>
            <NM.NMHeaderIcon
              src={massage}
              alt="Behandling" />
            <NM.NMHeader>
              Massage
            </NM.NMHeader>
          </NM.IconHeaderContainer>

          <Lib.CTAContainer>
            <Lib.CTAButton
              href="https://www.bokadirekt.se/places/haninge-naprapat-nordic-wellness-najaden-19603"
              aria-label="Boka tid på Haninge Naprapat Nordic Wellness Najaden"
              title="Boka tid på Haninge Naprapat Nordic Wellness Najaden"
              target="_blank"
              rel="noopener noreferrer">
              Boka Handen
            </Lib.CTAButton>

            <Lib.CTAButton
              href="https://www.bokadirekt.se/places/haninge-naprapat-core-gym-vega-28270"
              aria-label="Boka tid på Haninge Naprapat Core Gym Vega"
              title="Boka tid på Haninge Naprapat Core Gym Vega"
              target="_blank"
              rel="noopener noreferrer">
              Boka Vega
            </Lib.CTAButton>
          </Lib.CTAContainer>
        </NM.AlignContainer>
      </NM.NaprapatImgWithOverlay>


      <NM.CenterDivForInformation>
        <NM.NapMasContainer>
          <Lib.Header2>
            Massage skräddarsydd efter dina önskemål
          </Lib.Header2>
          <NM.NMIngressParagraph>
            Vi erbjuder massage på båda våra anläggningar. Våra massageterapeuter är certifierade och de skräddarsyr behandlingen efter dina önskemål.<br /><br />
            Du kan boka tid direkt online, eller ring till oss på {''}
            <NM.PhoneTo
              href="tel:087770670"
              aria-label="Ring oss på 08-777 06 70">
              08 - 777 06 70
            </NM.PhoneTo>
          </NM.NMIngressParagraph>

          <NM.MethodsContainer>
            <NM.ColumnContainer>
              <Lib.Header2>
                Idrottsmassage
              </Lib.Header2>
              <NM.NapMasParagraph>
                En idrottsmassage är mer kraftfull och djupgående än klassisk massage.
                Här mjukas musklerna upp med starka massagegrepp, triggerpunktsbehandling och stretch.
                Denna tjänst passar dig som vill ha massagebehandling av ett specifikt område eller
                helt enkelt föredrar lite tuffare behandling.
              </NM.NapMasParagraph>
            </NM.ColumnContainer>

            <NM.ColumnContainer>
              <Lib.Header2>
                Klassisk massage
              </Lib.Header2>
              <NM.NapMasParagraph>
                Klassisk massage är en behandling där fokus är att mjuka upp spända muskler,
                lindra stelhet och ge en effektiv avslappning. Massagen läggs upp efter kundens behov
                - det kan vara förebyggande eller för att behandla direkta problem.
              </NM.NapMasParagraph>
            </NM.ColumnContainer>
          </NM.MethodsContainer >

          <NM.MethodsContainer>
            <NM.ColumnContainer>
              <Lib.Header2>
                Lymfmassage
              </Lib.Header2>
              <NM.NapMasParagraph>
                Lymfmassage är en effektiv metod för att förbättra funktionen i lymfsystemet.
                Lymfmassage består av strykningar i lymfflödets riktning vilket stimulerar transporten
                av överskottsvätska och slaggprodukter ut ur kroppen. De olika teknikerna hjälper till
                att lossa på kompakt bindväv och öka lymfflödet.
                <br /> <br />
                Lymfmassage är en metod som passar alla. Den kan vara extra viktig för dig som har fått
                lymfknutor bortopererade eller strålats i samband med cancerbehandling, har någon typ av ödem,
                fibromyalgi, restless legs och trötthetssyndrom.
                Lymfmassage ges ej under pågående cytostatikabehandling.
              </NM.NapMasParagraph>
            </NM.ColumnContainer>

            <NM.ColumnContainer>
              <NM.NapMasParagraph $marginTopBM="52px" $marginTopD="70px" $marginTopT="62px">
                Tecken på att ditt lymfsystem kan behöva lite extra omhändertagande:
              </NM.NapMasParagraph>
              <NM.IconList icon={<img src={checkicon} alt="Check icon" />}>
                <span>Du känner dig tung och svullen</span>
                <span>Du är ständigt trött</span>
                <span>Du upplever en mental dimma och trötthet</span>
                <span>Du drabbas ofta av infektioner och förkylningar</span>
                <span>Du har värk och smärta</span>
                <span>Du har torr hy med utslag och eksem</span>
                <span>Du har domningar och pirrningar i armar och ben</span>
              </NM.IconList>
            </NM.ColumnContainer>
          </NM.MethodsContainer >
        </NM.NapMasContainer >
      </NM.CenterDivForInformation>

      <NM.NMGroupContainer>
        <OM.TeamStandup>
          <Lib.Header3>Våra massörer</Lib.Header3>
          <OM.TeamGrid>
            {TheTeam
              .filter(member => member.behandling === 'Massage')
              .map((member) => (
                <OM.TeamCard
                  key={member.email}
                  name={member.name}
                  education={member.education}
                  text={member.text}
                  img={member.img}
                  email={member.email}
                />
              ))}
          </OM.TeamGrid>
        </OM.TeamStandup>
      </NM.NMGroupContainer>

    </>
  );
}
