interface ReviewData {
  id: string;          // Add id field
  text: string;
  authorName: string;
  rating: number;
}

export const ReviewsData: ReviewData[] = [
  {
    id: 'review-1',    // Unique ID
    authorName: '– Carina J',
    text: 'Fick fantastisk hjälp med min onda höft och ländrygg. Rekommenderas varmt!',
    rating: 5,
  },
  {
    id: 'review-2',
    authorName: '– Sebastian L',
    text: 'Väldigt trevligt bemötande och vill verkligen hjälpa till att lösa de problemen man har, kunnig och erfaren!',
    rating: 5,
  },
  {
    id: 'review-3',
    authorName: '– Alexandra K',
    text: 'Väldigt bra lymfmassage, lugnt och väldigt rogivande för kropp & själ.',
    rating: 5,
  },
  {
    id: 'review-4',
    authorName: '– Sara J',
    text: 'Jag är mycket nöjd med behandlingen! Rekommenderas varmt ⭐️',
    rating: 5,
  },
  {
    id: 'review-5',
    authorName: '– Marta S',
    text: 'Fantastiskt upplevelse! Så mycket hjälp och så himla trevligt bemötande. Rekommenderar stark och super tack till Emelie 🙏',
    rating: 5,
  },
  {
    id: 'review-6',
    authorName: '– Stefan S',
    text: 'Som vanligt trevligt och glatt bemötande av en skicklig naprapat som kan sitt jobb.',
    rating: 5,
  },
  {
    id: 'review-7',
    authorName: '– Jonas L',
    text: 'Professionell och kunnig. Har hjälp mig med muskler som bråkat i åratal. Rekommenderas varmt',
    rating: 5,
  },
  {
    id: 'review-8',
    authorName: '– Malin N',
    text: 'Jennifer var grym! Knakade på exakt rätt ställen och nu är man i toppform igen!',
    rating: 5,
  },
  {
    id: 'review-9',
    authorName: '– Monica A',
    text: 'Fantastiskt bemötande och en härlig behandling, tack Emelie!',
    rating: 5,
  },
  {
    id: 'review-10',
    authorName: '– Dijana B',
    text: 'Helt fantastiskt! Så duktig och lyhörd. Gun⭐️',
    rating: 5,
  },
  {
    id: 'review-11',
    authorName: '– Victoria P',
    text: 'Oskar är helt otrolig. Professionell hjälp och behandling på högsta nivån! Rekommenderar varmt.',
    rating: 5,
  },
  {
    id: 'review-12',
    authorName: '– Carina J',
    text: 'Fick fantastisk hjälp med min onda höft och ländrygg. Rekommenderas varmt!',
    rating: 5,
  },
  {
    id: 'review-13',
    authorName: '– Marie S',
    text: 'Michael lyssnar och är engagerad. Han är kan sin sak och vet vad han gör.',
    rating: 5,
  },
  {
    id: 'review-14',
    authorName: '– Micael W',
    text: 'Otroligt kunnig och nogran. Absolut en av de bästa jag har stött på. Har gått på behandlingar sedan tidigt 80 tal.',
    rating: 5,
  },
  {
    id: 'review-15',
    authorName: '– Annelie L',
    text: 'Noggrann anamnes och undersökning, jag var trygg i behandlingen och Gustav var lyhörd för mina besvär och återbesök är bokat',
    rating: 5,
  },
];
