import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import haningeNaprapatLogo from '../../assets/logos/haninge_naprapat_logo.png';

interface DesktopImgProps {
  $isScrolled: boolean;
}
const HaningeNaprapatImg = styled.img<DesktopImgProps>`
  align-self: flex-end;
  width: ${(props) => (props.$isScrolled ? '120px' : '200px')};
  margin: 10px -10px;

  /* Tablet */
  @media (min-width: 768px) {
    margin: 18px 0;
    width: ${(props) => (props.$isScrolled ? '130px' : '230px')};
  }

  /* Desktop */
  @media (min-width: 1024px) {
    margin: 16px 0;
    width: ${(props) => (props.$isScrolled ? '180px' : '280px')};
    transition: width 0.3s ease;
  }
`;


// Logo with navigation to home
interface HaningeNaprapatLogoProps {
  $isScrolled: boolean;
  style?: React.CSSProperties;
}

export const HaningeNaprapatLogo: React.FC<HaningeNaprapatLogoProps> = ({ $isScrolled }) => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  return (
    <Link to="/" onClick={scrollToTop}>
      <HaningeNaprapatImg
        $isScrolled={$isScrolled}
        src={haningeNaprapatLogo}
        alt="Haninge Naprapat logo"
      />
    </Link>
  );
};


interface DesktopMenuProps {
  $isScrolled: boolean;
}

export const DesktopMenuContainer = styled.div<DesktopMenuProps>`
  display: none;

  /* Desktop */
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: ${(props) => (props.$isScrolled ? 'var(--main-light)' : 'transparent')};
    transition: background-color 0.3s ease;
  }
`;

export const DesktopLinkContainer = styled.div`
  display: none;

  /* Desktop */
  @media (min-width: 1024px) {
    display: flex;
    padding: 0 20px;
    gap: 45px;
  }
`;

export const BehandlingarContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--main-light);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  padding:  0;
  z-index: 100;
  min-width: 150px;
`;

export const DropdownLink = styled(Link)`
  display: block;
  padding: 10px 20px;
  color: var(--text-dark);
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: var(--accent-green);
    color: white;
  }
`;




export const DeskMenuLinks = styled(Link) <LinkLineProps>`
  display: none;

    /* Desktop */
    @media (min-width: 1024px) {
    font-size: 18px;
    position: relative;
    color: var(--text-dark);
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    padding-top: 5px;
    margin: 5px 0;
  }

  &:hover {
    color: var(--accent-green);
  }

  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 0;
    height: 3px;
    bottom: -2px;
    left: 50%;
    background-color: var(--accent-green);
    transform: translateX(-50%);
    transform-origin: center;
    transition: width 0.5s ease;
  }

  &:hover::after {
    width: 100%;
  }
`;



interface BurgerMenuProps {
  $isScrolled: boolean;
}

export const BurgerMenuContainer = styled.div<BurgerMenuProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: ${(props) => (props.$isScrolled ? 'var(--main-light)' : 'transparent')};
    transition: background-color 0.3s ease;


  /* Desktop */
  @media (min-width: 1024px) {
    display: none;
  }
`;

interface LinkLineProps {
  to?: string;
}

export const BurgerMenuLinks = styled(Link) <LinkLineProps>`
  position: relative;
  font-size: 18px;
  color: var(--text-dark);
  text-decoration: none;
  display: inline-block;
  margin: 5px 0;

  &:hover {
    color: var(--accent-green);
  }

  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 0;
    height: 3px;
    bottom: -2px;
    left: 50%;
    background-color: var(--accent-green);
    transform: translateX(-50%);
    transform-origin: center;
    transition: width 0.5s ease;
  }

  &:hover::after {
    width: 100%;
  }

  /* Tablet  */
  @media (min-width: 768px) {
    font-size: 22px;
  }

  /* Desktop */
  @media (min-width: 1024px) {
    font-size: 28px;
  }
`;

