import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { Introduction } from "../pages/introduction"
import { Naprapati } from "../pages/naprapati"
import { Massage } from "../pages/massage"
import { OmOss } from "../pages/about"
import { Treatments } from "../pages/treatments"
import { AboutIntroduction } from "../pages/about-intro"
import { Review } from "../pages/reviews"
import { Location } from "../pages/location"
import { ScrollToTop } from "./scrollTo"
import { NotFound } from "./not-found"
import { MainLayout } from "../mainlayout"


export const AppRoutes: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout>
              <Introduction />
              <AboutIntroduction />
              <Treatments />
              <Review />
              <Location />
            </MainLayout>
          }
        />

        <Route
          path="/om-oss"
          element={
            <MainLayout>
              <OmOss />
            </MainLayout>
          }
        />
        <Route
          path="/massage"
          element={
            <MainLayout>
              <Massage />
            </MainLayout>
          }
        />
        <Route
          path="/naprapat"
          element={
            <MainLayout>
              <Naprapati />
            </MainLayout>
          }
        />
        <Route
          path="*"
          element={<NotFound />}
        />

      </Routes>
    </Router>
  )
};