import React from "react";
import * as Lib from "../style/lib";
import * as Fot from "../style/footer-style";
import naprapat from '../../assets/logos/naprapat-icon.svg';
import massage from '../../assets/logos/massage-icon.svg';
import andtech from '../../assets/logos/andtech-logo.png';

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Fot.FooterContainer>

      <Fot.FootRowColumn>

        <Fot.FootColumn>
          <Fot.FotLocHeader>
            Behandlingar
          </Fot.FotLocHeader>


          <Fot.FootRow>
            <Fot.TreatmentIcon2
              src={naprapat}
              alt="Behandling" />
            <Fot.FootLinks
              to="/naprapat"
              aria-label="Läs mer om våra naprapatbehandlingar"
            >
              Naprapati
            </Fot.FootLinks>
          </Fot.FootRow>


          <Fot.FootRow>
            <Fot.TreatmentIcon2
              src={massage}
              alt="Behandling" />
            <Fot.FootLinks
              to="/massage"
              aria-label="Läs mer om våra massagebehandlingar"
            >
              Massage
            </Fot.FootLinks>
          </Fot.FootRow>
        </Fot.FootColumn>


        <Fot.FootCTA>
          <Fot.FootColumn>
            <Fot.FotLocHeader>
              Nordic Wellness najaden
            </Fot.FotLocHeader>
            <Fot.FotLocAdress>
              Handens Stationsväg 27, Handen
            </Fot.FotLocAdress>
            <Lib.CTAButton
              href="https://www.bokadirekt.se/places/haninge-naprapat-nordic-wellness-najaden-19603"
              aria-label="Boka tid på Haninge Naprapat Nordic Wellness Najaden"
              title="Boka tid på Haninge Naprapat Nordic Wellness Najaden"
              target="_blank"
              rel="noopener noreferrer">
              Boka Handen
            </Lib.CTAButton>
          </Fot.FootColumn>

          <Fot.FootColumn>
            <Fot.FotLocHeader>
              Core Vega Gym
            </Fot.FotLocHeader>
            <Fot.FotLocAdress>
              Vega Allé 2, Haninge
            </Fot.FotLocAdress>
            <Lib.CTAButton
              href="https://www.bokadirekt.se/places/haninge-naprapat-core-gym-vega-28270"
              aria-label="Boka tid på Haninge Naprapat Core Gym Vega"
              title="Boka tid på Haninge Naprapat Core Gym Vega"
              target="_blank"
              rel="noopener noreferrer">
              Boka Vega
            </Lib.CTAButton>
          </Fot.FootColumn>

        </Fot.FootCTA>


      </Fot.FootRowColumn>
      <Fot.FootLine />

      <Fot.DetailsRow>
        <Fot.FootDetails aria-label={`Copyright ${currentYear} Haninge Naprapat AB.`}>
          ©{currentYear} Haninge Naprapat AB.
        </Fot.FootDetails>

        <Fot.FootDetails>
          Webbplats skapad av <Fot.FootA href="https://www.andtech.se" target="_blank" rel="noopener noreferrer">Andtech AB</Fot.FootA>
          <Fot.ImgA
            href="https://www.andtech.se"
            aria-label="Andtech AB - webbutveckling och konsulttjänster"
            target="_blank"
            rel="noopener noreferrer">
            <Fot.AndTechA src={andtech} alt="Image Link" />
          </Fot.ImgA>
        </Fot.FootDetails>

      </Fot.DetailsRow>
    </Fot.FooterContainer>
  );
}
