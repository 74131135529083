import michael from '../../assets/teamimg/michael-300px.jpg';
import jennifer from '../../assets/teamimg/jennifer-300px.jpg';
import emelie from '../../assets/teamimg/emelie-300px.jpg';
import gun from '../../assets/teamimg/gun-300px.jpg';
import gustav from '../../assets/teamimg/gustav-300px.jpg';
import oskar from '../../assets/teamimg/oskar-300px.jpg';

interface Team {
  name: string;
  education: string;
  text: string;
  img: string;
  email: string;
  behandling: string;
}

export const TheTeam: Team[] = [
  {
    name: 'Michael Palm',
    education: 'Leg. Naprapat 1994. Examensår 1983',
    text: 'Michael är en mycket erfaren naprapat som har drivit sin praktik och arbetat som naprapat sedan 1984. Är han inte i praktiken så hittar ni honom garanterat i CrossFitboxen.',
    img: michael,
    email: 'michael@haningenaprapat.se',
    behandling: 'Naprapati'
  },
  {
    name: 'Jennifer Palm',
    education: 'Leg. Naprapat 2011. Examensår 2010',
    text: 'Jennifer gick i sin fars fotspår och hoppade på naprapatutbildningen direkt efter gymnasiet. Driver idag Haninge Naprapat tillsammans med Micke. Hon har en bakgrund som truppgymnast men tränar idag CrossFit.',
    img: jennifer,
    email: 'jennifer@haningenaprapat.se',
    behandling: 'Naprapati'
  },
  {
    name: 'Gustav Wilhelmsson',
    education: 'Leg. Naprapat 2012. Examensår 2011',
    text: 'Duktig, erfaren och engagerad naprapat och personlig tränare som arbetat sedan 2011. Han brinner extra mycket för styrketräning med basövningar och är expert inom rehabilitering och skadeförebyggande åtgärder när det gäller att bygga en stark och hållbar fysik.',
    img: gustav,
    email: 'gustav@haningenaprapat.se',
    behandling: 'Naprapati'
  },
  {
    name: 'Emelie Rydén',
    education: 'Leg. Naprapat 2011. Examensår 2010',
    text: 'Driven naprapat med fokus på varje individs helhet där idrottsmedicin, psykologi och träning är basen. Fokus är hållbarhet, individanpassning och långsiktighet. Emelie har spelat handboll i 15 år men idag väljer hon gärna att styrketräna eller springa en runda i skogen.',
    img: emelie,
    email: 'emelie@haningenaprapat.se',
    behandling: 'Naprapati'
  },
  {
    name: 'Oskar Lundell',
    education: 'Dipl. massageterapeut',
    text: 'Tar emot kunder på Najaden. Klassisk massage, idrottsmassage samt lymfmassage.',
    img: oskar,
    email: ' ',
    behandling: 'Massage'
  },
  {
    name: 'Gun Salomonsson',
    education: 'Dipl. massageterapeut',
    text: 'Tar emot kunder i Vega. Klassisk massage, idrottsmassage samt lymfmassage.',
    img: gun,
    email: 'gun@haningenaprapat.se',
    behandling: 'Massage'
  },
];