import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

export const Header1 = styled.h1`
  font-size: 40px;
  margin-bottom: 26px;
  font-weight: 700;
  color: var(--header);

  @media (min-width: 768px) {
    font-size: 50px;
  }

  @media (min-width: 1024px) {
    font-size: 60px;
  }
`;

interface Header2Props {
  $textAlign?: 'left' | 'center';
};

export const Header2 = styled.h2<Header2Props>`
  font-size: 22px;
  margin-bottom: 26px;
  font-weight: 700;
  text-align: ${props => props.$textAlign || 'left'};
  color: var(--header);

  @media (min-width: 768px) {
    font-size: 30px;
    white-space: nowrap;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
  }
`;

export const Header3 = styled.h3`
  font-size: 26px;
  margin-bottom: 26px;
  font-weight: 700;
  color: var(--header);
  margin-bottom: 4rem;

  @media (min-width: 768px) {
    font-size: 30px;
    margin-bottom: 5rem;
  }

  @media (min-width: 1024px) {
    font-size: 36px;
  }
`;

export const HeaderAboutIntroH3 = styled.h3`
 font-size: 16px;
  margin-bottom: 26px;
  font-weight: 700;
  text-align: left;
  color: var(--header);

  @media (min-width: 768px) {
    font-size: 20px;
  }

  @media (min-width: 1024px) {
    font-size: 26px;
  }
`;

interface Header5Props {
  $marginBottom?: string;
};

export const Header5 = styled.h5<Header5Props>`
  font-size: 16px;
  margin-bottom: ${props => props.$marginBottom || '26px'};
  font-weight: 700;
  text-align: left;
  color: var(--header);

  @media (min-width: 768px) {
    font-size: 20px;
  }

  @media (min-width: 1024px) {
    font-size: 26px;
  }
`;

interface BreadParagrapthProps {
  $textAlign?: 'left' | 'center';
  $TmaxWidth?: string;
  $DmaxWidth?: string;
};

export const BreadParagraph = styled.p<BreadParagrapthProps>`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  text-align: ${props => props.$textAlign || 'left'};
  max-width: 600px;

  @media (min-width: 768px) {
    font-size: 18px;
    max-width: ${props => props.$TmaxWidth || '600px'};
  }

  @media (min-width: 1024px) {
    font-size: 20px;
    max-width: ${props => props.$DmaxWidth || '600px'};
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;

  @media (min-width: 725px) {
    gap: 2rem;
    margin-top: 2rem;
  }

  @media (min-width: 1024px) {
    margin-top: 3rem;
  }
`;

export const CTAButton = styled.a`
  display: inline-block;
  border-radius: 50px;
  background-color: var(--accent-green);
  color: var(--main-light);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  padding: 3px 15px;
  margin: 10px 0;
  line-height: 38px; 
  text-align: center;
  text-decoration: none; 
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1); 
  }

  &:active {
    background-color: var(--header); 
    border: 3px solid var(--header);
    transform: scale(0.98);
  }

  @media (min-width: 1024px) {
    font-size: 16px;
    margin: 0;
    line-height: 45px;
    padding: 3px 22px;
  }

`;

interface ReDirectButtonProps {
  to?: string;
};

export const RedDirectButton = styled(Link) <ReDirectButtonProps>`
  display: inline-block;
  border-radius: 50px;
  border: 3px solid var(--accent-green);
  background-color: var(--accent-green);
  color: var(--main-light);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  width: 140px;
  padding: 0 12px;
  margin-top: 15px;
  line-height: 38px; 
  text-align: center;
  text-decoration: none; 
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1); 
  }

  &:active {
    background-color: var(--header); 
    border: 3px solid var(--header);
    transform: scale(0.98);
  }
`;



// jumping arrow on introduction page
const jump = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px); 
  }
`;

export const Arrow = styled.img`
  width: 30px;
  height: auto;
  bottom: 40px;
  position: absolute;
  transform: translateY(-0%);
  opacity: 1;
  animation: ${jump} 1s ease-in-out 5;
  animation-delay: 3s;
  transition: opacity 0.5s ease;
`;


// WhiteOverlay for background images
export const WhiteOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.57); 
`;


export const PlainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--main-light);
  align-items: center;
  justify-content: center;
  height: 100vh;
  `;