import React from "react";
import styled from "styled-components";
import * as Lib from "../style/lib";
import IntroImg from '../../assets/img/HN-introimageNew.png';

const IntroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-light);
  padding: 1rem;
  flex-wrap: wrap;
  
  /* Tablet styles */
  @media (min-width: 768px) {
    padding: 2rem; 
  }

  /* Desktop styles */
  @media (min-width: 1024px) {
    padding: 6rem; 
  
  }
`;

const IntroContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 0 50px 0;

    /* Tablet styles */
    @media (min-width: 768px) {
      padding: 2rem 0 60px 0;
  }

  /* Desktop styles */
  @media (min-width: 1024px) {
    padding: 6rem 1.5rem 6rem 0; 
  }
`;



const CollageImg = styled.img`
  width: 100%; 
  height: auto; 


    /* Tablet styles */
    @media (min-width: 768px) {
    max-width: 600px; 
    height: auto; 
  }

    /* Desktop styles */
  @media (min-width: 1024px) {
    width: 600px; 
    height: auto;
    margin-right: 8rem;
  }
  
  /* Desktop styles */
  @media (min-width: 1440px) {
    width: 600px; 
    height: auto;
    margin-left: 2rem;
  }
`;


export const AboutIntroduction: React.FC = () => {
  return (
    <IntroContainer>
      <IntroContainerColumn>
        <Lib.Header2>
          Välkommen till oss på Haninge Naprapat AB
        </Lib.Header2>
        <Lib.HeaderAboutIntroH3>
          – din naprapat i Haninge sedan 1984.
        </Lib.HeaderAboutIntroH3>
        <Lib.BreadParagraph>
          Till oss kommer du för att få hjälp med ditt smärttillstånd eller för en välgörande massage.
          Vi undersöker och behandlar de flesta besvär relaterade till muskler och leder.
        </Lib.BreadParagraph>
        <Lib.Header5>
          Varmt Välkommen!
        </Lib.Header5>

        <Lib.RedDirectButton
          to="/#bokabehandling"
          aria-label="Boka tid för behandling hos Haninge Naprapat">
          Boka tid
        </Lib.RedDirectButton>
      </IntroContainerColumn>
      <CollageImg
        src={IntroImg}
        alt="Haninge Naprapat och massage behandlingar" />
    </IntroContainer>
  );
}
