import React, { ReactNode } from "react";
import styled from "styled-components";

export const CenterDivForInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--main-light);
  padding: 0 0.3rem;
`;

export const NapMasContainer = styled.div`
  display: flex;
  background-color: var(--main-light);
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 1rem;

  @media (min-width: 768px) {
    padding: 5rem 3rem 2rem 3rem;
  }

  @media (min-width: 1024px) {
    padding: 5rem 4rem;
  }
  `;

export const NMGroupContainer = styled.div`
  display: flex;
  background-color: var(--main-light);
  flex-direction: column;
  padding: 1rem 0.5rem;
  
  @media (min-width: 768px) {
    padding: 1.5rem 3rem;
  }

  @media (min-width: 1024px) {
    padding: 1rem 3rem;
  }
  `;

export const IconHeaderContainer = styled.div`
display: flex;
justify-content: flex-start;
margin-bottom: 26px;
`;

export const NMHeaderIcon = styled.img`
width: 60px; 
height: auto; 
margin-right: 1rem;
`;

export const AlignContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  `;

export const NMHeader = styled.h2`
  font-size: 35px;
  font-weight: 700;
  text-align: left;
  color: var(--main-dark);
  margin-top: 8px;

  @media (min-width: 768px) {
    font-size: 45px;
  }

  @media (min-width: 1024px) {
    font-size: 55px;
  }
`;

// Image wrapper for overlay
const ImageWrapper = styled.div<{ src: string }>`
  position: relative;
  height: 80vh;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Overlay for image
const WhiteOverlayOm = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.321); 
`;

// Content styled
const Content = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
`;

// Reusable component
interface NaprapatImgWithOverlayProps {
  src: string;
  children: React.ReactNode;
}

export const NaprapatImgWithOverlay: React.FC<NaprapatImgWithOverlayProps> = ({ src, children }) => {
  return (
    <ImageWrapper src={src}>
      <WhiteOverlayOm />
      <Content>
        {children}
      </Content>
    </ImageWrapper>
  );
};


export const NMIngressParagraph = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  text-align:left;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    max-width: 980px;
  }
`;

interface NapMasParagraphProps {
  $marginTopBM?: string;
  $marginTopT?: string;
  $marginTopD?: string;
}

// Textsection in both naprapati and massage
export const NapMasParagraph = styled.p<NapMasParagraphProps>`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  text-align:left;
  max-width: 290px;

  @media (min-width: 641px) {
    font-size: 16px;
    min-width: 300px;
    max-width: 310px;
    margin-top: ${props => props.$marginTopBM || '0'};
  }

  @media (min-width: 768px) {
    font-size: 16px;
    min-width: 320px;
    max-width: 350px;
    margin-top: ${props => props.$marginTopT || '0'};
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    max-width: 600px;
    margin-top: ${props => props.$marginTopD || '0'};
  }
`;

export const PhoneTo = styled.a`
  font-size: 14px;
  margin: 0;
  font-weight: 700;
  text-align: left;
  text-decoration: none;
  color: var(--main-dark);

  &:hover {
    color: var(--accent-green);
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;


// Div for treatment text and list
export const MethodsContainer = styled.div`
  display: flex;
  background-color: var(--main-light);
  flex-wrap: wrap;
  margin-top: 1.2rem;
  gap: 1rem;

  @media (min-width: 641px) {
    flex-wrap: nowrap;
    gap: 2rem; 
  }

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    gap: 4rem; 
  }
  `;

export const ColumnContainer = styled.div`
display: flex;
background-color: var(--main-light);
flex-direction: column;
`;


// Styling fot reasonlist 
interface CheckListProps {
  icon: JSX.Element;
  children: ReactNode;
}

const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  align-items: flex-start;
`;

const Icon = styled.span`
  margin-right: 4px; 
  width: 16px; 
  height: 16px;
  display: inline-flex; 
  align-items: center; 
  justify-content: center; 
  flex-shrink: 0;

  @media (min-width: 768px) {
    width: 22px; 
    height: 22px;
    margin-right: 6px; 
  }

  @media (min-width: 1024px) {
    width: 24px; 
    height: 24px;
    margin-right: 8px; 
  }

  img {
    width: 100%; 
    height: 100%;
  }
`;

const SpanText = styled.span`
  font-size: 14px; 
  color: var(--main-dark);
  font-weight: 600;
  text-align: left;
`;

export const IconList: React.FC<CheckListProps> = ({ icon, children }) => {
  const items = React.Children.toArray(children);

  return (
    <ListContainer>
      {items.map((item, index) => (
        <ListItem key={index}>
          <Icon>{icon}</Icon>
          <SpanText>{item}</SpanText>
        </ListItem>
      ))}
    </ListContainer>
  );
};