import styled from "styled-components";
import teamHN from "../../assets/teamimg/teamHN.jpg";
import React from "react";

export const AboutContainer = styled.div`
  display: flex;
  background-color: var(--main-light);
  flex-direction: column;
  padding: 7rem 0.5rem;
  
  @media (min-width: 768px) {
    padding: 8rem 3rem;
  }

  @media (min-width: 1024px) {
    padding: 9rem 3rem;
  }
  `;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 26px;
  `;

export const TreatmentIcon = styled.img`
  width: 60px; 
  height: auto; 
  margin-right: 1rem;
  `;

// Image wrapper for overlay
export const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 3rem;
  `;

// Overlay for image
export const WhiteOverlayOm = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.321); 
`;

// Image styling
export const TeamImage = styled.img`
  width: 100%;
  height: auto;
`;

export const TeamImgWithOverlay: React.FC = () => {
  return (
    <ImageWrapper>
      <TeamImage src={teamHN} alt="Team" />
      <WhiteOverlayOm />
    </ImageWrapper>
  );
};


export const AboutIntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  `;


export const TeamStandup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 1rem 0;

  @media (min-width: 768px) {
    margin:2rem 0 1rem 0;
  }

  @media (min-width: 1024px) {
    margin: 1rem 0 1rem 0;
  }
  `;


// OBS REUSABLE CARDCOMPONENT FOR MASSAGE AND NAPRAPATHY PAGES
export const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr; 
  gap: 0.5rem; 
  
  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem; 
  }
`;

export const AboutCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--card-light);
  padding: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  max-width: 400px;

  @media (min-width: 768px) {
    min-width: 350px;
    padding: 1.5rem;
  }

  @media (min-width: 1024px) {
    max-width: 540px;
    height: 350px;
  }
`;

export const ProfileImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: -38px; 
  right: 18px; 

  @media (min-width: 768px) {
    top: -52px; 
    right: 15px; 
  }

  @media (min-width: 1024px) {
    width: 160px;
    height: 160px;
    top: -42px; 
    right: 35px; 
  }
`;

export const CardTextContainer = styled.div`
  margin-top: 70px;
  text-align: left;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-top: 60px;
  }
`;

export const PersonName = styled.h2`
  font-size: 26px;
  color: var(--header);
  margin-bottom: 0.5rem;
`;

export const Subtitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  color: var(--main-dark);
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 15px;
  }
`;

export const Description = styled.p`
  font-size: 16px;
  color: var(--text-dark);
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const Email = styled.a`
  font-size: 16px;
  color: var(--main-dark);
  font-weight: 600;
  text-decoration: none;

  &:hover {
    color: var(--accent-green);
  }
`;

interface Team {
  name: string;
  education: string;
  text: string;
  img: string;
  email: string;
}

export const TeamCard: React.FC<Team> = ({ name, education, text, img, email }) => (
  <AboutCard>
    <ProfileImage src={img} alt={name} />
    <CardTextContainer>
      <PersonName>{name}</PersonName>
      <Subtitle>{education}</Subtitle>
      <Description>{text}</Description>
      <Email href={`mailto:${email}`}>{email}</Email>
    </CardTextContainer>
  </AboutCard>
);




// Friskvardssolutions
export const FriskvardContainer = styled.div`
  display: flex;
  background-color: var(--card-light);
  flex-wrap: wrap; 
  justify-content: center;
  align-items: center; 
  padding: 1rem 0.2rem;
  gap: 0.8rem;
  
  @media (min-width: 768px) {
    padding: 1.5rem 1rem;
  }

  @media (min-width: 1024px) {
    padding: 2rem o;
  }
  `;


export const LogoWrapper = styled.div`
  height: 50px; 
  display: flex;
  justify-content: center;
  align-items: center; 
  overflow: hidden; 

  @media screen and (min-width: 768px) {
    height: 90px; 
  }

  @media screen and (min-width: 1024px) {
    height: 90px;
  }
`;

export const LpCaseLogo = styled.img`
  height: auto; 
  max-height: 100%; 
  width: auto; 
  object-fit: contain;
`;