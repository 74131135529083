import styled, { keyframes, css } from "styled-components";

export const ReviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: var(--main-light);
  background-color: var(--main-dark);
  height: 350px;

  /* Tablet styles */
  @media (min-width: 768px) {
    align-items: flex-start;
    padding-top: 6rem;
    gap: 5rem;
  }

  /* Desktop styles */
  @media (min-width: 1024px) {
    gap: 7rem;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;


export const ReviewContainerColumn = styled.div<{ $fadeOut: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  width: 265px;

  ${({ $fadeOut }) =>
    css`
      animation: ${$fadeOut ? fadeOutAnimation : fadeIn} 1s ease-in-out;
    `}
`;

export const ReviewParagraph = styled.p`
font-size: 14px;
margin-bottom: 26px;
font-weight: 400;
font-family: 'inter', sans-serif;
font-style: italic;
text-align: left;
color: var(--main-light);

@media (min-width: 768px) {
  font-size: 16px;
}

@media (min-width: 1024px) {
  font-size: 18px;
}
`;


export const NameParagraph = styled.p`
font-size: 14px;
margin-bottom: 26px;
font-weight: 500;
text-align: left;
color: var(--accent-yellow);

@media (min-width: 768px) {
  font-size: 16px;
}

@media (min-width: 1024px) {
  font-size: 18px;
}
`;