import React from 'react';
import { Footer } from './utils/footer';
import { DesktopMenu } from './utils/desktop-menu';
import { HamburgerMenu } from './utils/hamburger-menu';

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <>
      <DesktopMenu />
      <HamburgerMenu />
      <main>{children}</main>
      <Footer />
    </>
  );
};