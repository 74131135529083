import React from "react";
import naprapat from '../../assets/logos/naprapat-icon.svg';
import massage from '../../assets/logos/massage-icon.svg';
import * as Treat from "../style/treatment-style";
import * as Lib from "../style/lib";



export const Treatments: React.FC = () => {
  return (
    <Treat.TreatmentBG>
      <Lib.WhiteOverlay />
      <Treat.TreatmentContent>

      <section aria-labelledby="naprapat-title">
        <Treat.TreatmentContainer>
       
          <Treat.IconContainer>
            <Treat.TreatmentIcon
              src={naprapat}
              alt="Ikon för massagebehandling" />
            <Treat.Header3 id="naprapat-title">
              Naprapat
            </Treat.Header3>
          </Treat.IconContainer>
          <Treat.TreatmentParagraph>
            När samspelet mellan muskler, leder och nervsystem inte fungerar normalt
            uppstår smärta. Genom att man behandlar både muskler och leder uppnås en
            bättre funktion och rörlighet i hela kroppen.
          </Treat.TreatmentParagraph>

          <Treat.ReadMoreArrow
            to="/naprapat"
            aria-label="Läs mer om naprapatbehandling"
            text="Läs mer" />
         
        </Treat.TreatmentContainer>
        </section>

        <section aria-labelledby="massage-title">
        <Treat.TreatmentContainer>
          <Treat.IconContainer>
            <Treat.TreatmentIcon
              src={massage}
              alt="Ikon för massagebehandling" />
            <Treat.Header3 id="massage-title">
              Massage
            </Treat.Header3>
          </Treat.IconContainer>
          <Treat.TreatmentParagraph>
            Vi erbjuder massage på båda våra anläggningar. Våra massageterapeuter är
            certifierade och de skräddarsyr behandlingen efter dina önskemål.
          </Treat.TreatmentParagraph>

          <Treat.ReadMoreArrow
            to="/massage"
            aria-label="Läs mer om Massagebehandling"
            text="Läs mer" />
        </Treat.TreatmentContainer>
        </section>

      </Treat.TreatmentContent>
    </Treat.TreatmentBG>
  );
}
