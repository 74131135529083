import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  
  /* CSS reset */
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* Global styles */
  body {
    background-color: var(--mainlight);
    color: var(--darktext);
    margin: 0;
    font-family: 'Inter',  sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    scroll-behavior: smooth;
  }

  :root {
    --main-light: #FFFEF2;
    --main-dark: #252525;
    --header: #114036;
    --text-dark: #191919;
    --accent-green: #16af8e;
    --accent-yellow: #f9b73f;
    --card-light: #f9f3d9;
  }

  p {
    margin: 0;
  }

  p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

  img, picture, video, canvas, svg {
    display: block;
  }

  input, button, textarea, select {
    font: inherit;
  }

  #root, #__next {
    isolation: isolate;
    height: 100%;
  }

  /* Utility classes */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }

`;