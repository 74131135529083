import React, { useState } from 'react';
import styled from "styled-components";


export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: var(--main-light);
  padding-top: 4.5rem;
  
  /* Tablet styles */
  @media (min-width: 768px) {
    padding: 5rem 1rem; 
  }

  /* Desktop styles */
  @media (min-width: 1024px) {
    padding: 6rem; 
  }
`;


export const LocationRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  background-color: var(--main-light);
  gap: 2rem;
  
  /* Tablet styles */
  @media (min-width: 768px) {
    padding: 2rem; 
    align-items: flex-start;
    flex-direction: row;
    gap: 3rem;
  }

  /* Desktop styles */
  @media (min-width: 1024px) {
    margin: 0 3rem; 
    gap: 4rem;
  }
`;

export const GeoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MapDiv = styled.div`
  height: 220px;
  background-color: #e0e0e0; 
  border: none; 
  border-radius: 8px; 
  margin-bottom: 1rem;
  width: 310px;

  @media (min-width: 768px) {
    height: 250px;
    width: 350px;
  }

  @media (min-width: 1024px) {
    height: 370px;
    width: 480px;
  }
`;

export const LocationHeader4 = styled.h4`
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 700;
  text-align: center;
  color: var(--header);
  white-space: nowrap;

  @media (min-width: 768px) {
    font-size: 19px;
  }

  @media (min-width: 1024px) {
    font-size: 22px;
  }
`;



export const IconCircle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--header);
  color: var(--main-light);
  margin-bottom: 10px;
  cursor: pointer;

  svg {
    width: 35px; 
    height: 35px; 
  }

  &:hover {
    cursor: copy;
  }
`;


export const GeoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GeoParagraph = styled.p`
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  text-align: left;
  color: var(--main-dark);
`;

export const UnderParagraph = styled.p`
  font-size: 10px;
  margin-top: 3px;
  font-weight: 400;
  text-align: left;
  color: var(--main-dark);
`;


export const LinkA = styled.a`
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  text-align: left;
  text-decoration: none;
  color: var(--main-dark);

  &:hover {
    color: var(--accent-green);
  }
`;
const IconWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const CopyConfirmation = styled.div`
  position: absolute;
  background-color: var(--header);
  color: var(--main-light);
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  bottom: 120%;
  width: 150px;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
`;

interface LocationItemProps {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  mainText: string | string[];
  subText?: string;
  linkType?: 'phone' | 'email' | 'address';
  ariaLabel?: string;
}

export const LocationItem: React.FC<LocationItemProps> = ({
  Icon,
  mainText,
  subText,
  linkType,
  ariaLabel,
}) => {
  const [copyConfirmationVisible, setCopyConfirmationVisible] = useState(false);

  const handleCopy = () => {
    let textToCopy = '';

    if (linkType === 'phone' || linkType === 'email') {
      textToCopy = mainText as string;
    } else if (linkType === 'address') {
      textToCopy = Array.isArray(mainText) ? mainText.join(', ') : mainText;
    }

    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setCopyConfirmationVisible(true);
          setTimeout(() => {
            setCopyConfirmationVisible(false);
          }, 2000);
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    } else {
      console.error('Clipboard API not supported');
    }
  };

  const handleInteraction = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault(); // Prevent default behavior for both click and touch
    handleCopy();
  };

  const renderMainText = () => {
    if (Array.isArray(mainText)) {
      return mainText.map((text, index) => (
        <GeoParagraph key={index}>{text}</GeoParagraph>
      ));
    }
    return <GeoParagraph>{mainText}</GeoParagraph>;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <IconWrapper
        onClick={handleInteraction}
        onTouchEnd={handleInteraction} // Add touch support for mobile devices
      >
        <IconCircle>
          <Icon aria-label={ariaLabel} />
        </IconCircle>
        <CopyConfirmation className={copyConfirmationVisible ? 'visible' : ''}>
          {linkType === 'phone'
            ? 'Telefonnummer kopierat'
            : linkType === 'email'
              ? 'E-postadress kopierad'
              : 'Fakturaadress kopierad'}
        </CopyConfirmation>
      </IconWrapper>
      {linkType === 'phone' ? (
        <LinkA href={`tel:${mainText}`} aria-label={`Call ${mainText}`}>
          {mainText}
        </LinkA>
      ) : linkType === 'email' ? (
        <LinkA href={`mailto:${mainText}`} aria-label={`Email ${mainText}`}>
          {mainText}
        </LinkA>
      ) : (
        renderMainText()
      )}
      {subText && <UnderParagraph>{subText}</UnderParagraph>}
    </div>
  );
};
